<script setup lang="ts">
import type { SegmentedPopup } from '~/types/storyblok/shared-content/segmented-popup'
import {
  AlertDialogCancel,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogOverlay,
  AlertDialogPortal,
  AlertDialogRoot,
  AlertDialogTitle,
} from 'radix-vue'

interface Props {
  blok: SegmentedPopup
}

const props = defineProps<Props>()
const emit = defineEmits(['close'])

const blok = toRef(props, 'blok')

const segmentedPopup = computed(() => blok.value)
const legal = useRichtext(computed(() => segmentedPopup.value.legal))
const successMessage = useRichtext(computed(() => segmentedPopup.value.successMessage))
const errorMessage = useRichtext(computed(() => segmentedPopup.value.errorMessage))
const mediaStore = useMediaStore()
const isMobile = computed(() => {
  return ['tablet', 'mobile'].includes(mediaStore.device)
})

const formRequeststate = reactive({
  success: false,
  error: false,
})

const altText = computed(() => isMobile.value ? segmentedPopup.value.mobileAltText : segmentedPopup.value.desktopAltText)

function closePopup() {
  emit('close')
}
</script>

<template>
  <AlertDialogRoot
    default-open
    class="root"
  >
    <AlertDialogPortal>
      <AlertDialogOverlay class="overlay" />
      <AlertDialogContent class="content">
        <div
          v-if="!formRequeststate.success"
          class="content__text"
        >
          <AlertDialogTitle
            v-if="segmentedPopup.title"
            class="title"
          >
            {{ segmentedPopup.title }}
          </AlertDialogTitle>
          <AlertDialogDescription
            v-if="segmentedPopup.description"
            class="description"
          >
            {{ segmentedPopup.description }}
          </AlertDialogDescription>
          <FormForm
            v-if="segmentedPopup.form"
            :fields="segmentedPopup.form"
            :legal="legal"
            :label="segmentedPopup.ctaLabel"
            signup-source="popup"
            :identifier="segmentedPopup.popupIdentifier"
            @success="formRequeststate.success = true"
            @error="formRequeststate.error = true"
          />
          <div
            v-if="formRequeststate.error"
            v-html="errorMessage"
          />
        </div>
        <AlertDialogCancel
          class="close"
          :aria-label="$t('close')"
          @click="closePopup"
        >
          <span class="close-icon" />
        </AlertDialogCancel>
        <div
          v-if="formRequeststate.success"
          class="success"
          v-html="successMessage"
        />
        <ImageResponsive
          v-if="segmentedPopup.mobileAsset && segmentedPopup.desktopAsset"
          provider="storyblok"
          :src="segmentedPopup.mobileAsset || ''"
          :alt="altText"
          :large-asset="segmentedPopup.desktopAsset"
          :lazy="true"
          object-fit="contain"
          class="image"
        />
      </AlertDialogContent>
    </AlertDialogPortal>
  </AlertDialogRoot>
</template>

<style lang="scss" scoped>
@import 'assets/scss/rules/breakpoints';
@import 'assets/scss/typography/body';
@import 'assets/scss/typography/headings';

.root {
  z-index: 18;
}

.overlay {
  background-color: var(--overlay-in-bg);
  position: fixed;
  inset: 0;
  animation: overlayShow 150ms cubic-bezier(0.16, 1, 0.3, 1);
  // Must be 17 to override the header z-index
  z-index: 17;
}

.content {
  display: flex;
  flex-direction: column;
  background-color: var(--white);
  gap: 2rem;
  border-radius: 6px;
  box-shadow: hsl(206 22% 7% / 35%) 0px 10px 38px -10px, hsl(206 22% 7% / 20%) 0px 10px 20px -15px;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 90vw;
  max-width: 80rem;
  max-height: 85vh;
  padding: 2.8rem;
  animation: contentShow 150ms cubic-bezier(0.16, 1, 0.3, 1);
  // Must be 17 to override the header z-index
  z-index: 17;
  overflow-y: scroll;
  scrollbar-width: none;

  &:focus {
    outline: none;
  }

  &__text {
    flex: 1;
  }

  @media (min-width: $tablet) {
    flex-direction: row;
    padding: 4rem;
    gap: 4rem;
  }
}

.title {
  margin: 0;
  margin-bottom: 1.8rem;
  @include h5;
}

.success {
  @include h5;
  flex: 1;
  align-self: center;
}

.description {
  margin-bottom: 1.8rem;
  @include body2;
}

.subscribe {
  width: 100%;
}

.legal {
  @include caption1;
  color: inherit;

  &:deep(a:focus-visible) {
    outline: 2px solid var(--black);
  }
}

.close {
  padding: 1rem;
  position: absolute;
  top: 0.6rem;
  right: 0.6rem;

  &-icon {
    display: block;
    background-image: url('/icons/close.svg');
    background-repeat: no-repeat;
    background-size: contain;
    width: 1.5rem;
    height: 1.5rem;
  }
}

.image {
  align-self: center;
  flex: 1;
  width: 100%;
}

@keyframes overlayShow {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

@keyframes contentShow {
  from {
    opacity: 0;
    transform: translate(-50%, -48%) scale(0.96);
  }

  to {
    opacity: 1;
    transform: translate(-50%, -50%) scale(1);
  }
}
</style>
